import React from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { graphql } from 'gatsby';

import { Container, Row, Col, Button } from "react-bootstrap";

import imgH from "../../assets/image/snorefreeswiss/shutterstock_674031385.jpg"

const Hero = () => {
  const { t } = useTranslation();

  return (
    <>
      {/* <!-- Hero Area --> */}
      <div
        className="bg-image position-relative z-index-1 bg-gradient-black pb-17 pt-24 pt-lg-32 pb-lg-28"
        css={`
        background-image: url(${imgH});
        `}
      >
        <Container>
          <Row className="align-items-center">
            <Col
              md="9"
              lg="7"
              xl="6"
              data-aos="fade-right"
              data-aos-duration="750"
              data-aos-delay="500"
            >
              <div className="hero-content section-title dark-mode-texts">
                <h1 className="gr-text-2 fw-bold mb-8 text-white">
                  {t('hero_title')}
                </h1>
                <p className="gr-text-8 pe-sm-7 pe-md-12 text-color-opacity">
                  {t('hero_subtitle')}
                </p>
                <div className="hero-btns mt-10">
                  <Button className="with-icon gr-hover-y">
                    {t('hero_cta')}
                    <i className="icon icon-tail-right fw-bold"></i>
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Hero;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

